import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {environment} from "../../../../../../environments/environment";
import {GeneralScrollbarMarginService} from "../../../../services/general-scrollbar-margin/general-scrollbar-margin.service";
import {AuthService} from "../../../../services/auth/auth.service";
import {SelectedCourseService} from "../../../../services/selected-course/selected-course.service";

@Component({
  selector: 'app-desktop-header',
  templateUrl: './desktop-header.component.html',
  styleUrls: ['./desktop-header.component.scss']
})
export class DesktopHeaderComponent implements AfterViewInit {

  @ViewChild('desktopHeader', {static: true}) desktopHeader: ElementRef;

  wpSiteUrl = environment.wpSiteUrl;

  constructor(private generalScrollbarMarginService: GeneralScrollbarMarginService,
              public authService: AuthService,
              public selectedCourseService: SelectedCourseService) {
  }

  ngAfterViewInit(): void {
    this.generalScrollbarMarginService.observe(this.desktopHeader.nativeElement);
  }

}
