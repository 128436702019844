import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, throwError} from "rxjs";
import {DashboardResponse} from "../../data-models/dashboard-response";
import {DashboardDataService} from "../dashboard-data/dashboard-data.service";
import {catchError, switchMap, tap} from "rxjs/operators";
import {AuthService} from "../auth/auth.service";

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private dashboard$ = new BehaviorSubject<DashboardResponse>(null);
  private loading = false;

  constructor(private dashboardDataService: DashboardDataService,
              private authService: AuthService,
  ) {
  }

  loadDashboard(): Observable<DashboardResponse> {
    this.loading = true;
    return this.dashboardDataService.getDashboard().pipe(tap((dashboard: DashboardResponse) => {
      this.dashboard$.next(dashboard);
      this.loading = false;
    }), catchError((err, caught) => {
      this.loading = false;
      return throwError(err);
    }))
  }

  loadIfNeeded(): Observable<DashboardResponse> {
    if (this.dashboard$.getValue() || this.loading) {
      return this.dashboard$.asObservable();
    }
    return this.loadDashboard();
  }

  getDashboard$(): Observable<DashboardResponse> {
    return this.dashboard$.asObservable();
  }

  changeCourse(subscriptionId: number, changeToCourseId: number): Observable<any> {
    return this.dashboardDataService.changeCourse(subscriptionId, changeToCourseId).pipe(
      switchMap(() => this.authService.refreshUserData())
    )
  }

  clear(): void {
    this.dashboard$.next(null);
  }
}
