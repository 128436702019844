<div #desktopHeader class="desktop-header desktop-header--white-theme" *ngIf="authService.getLoggedUserOnce() | async as user">
  <div class="container-fluid h-100 ">
    <div class="d-flex  align-items-center justify-content-between h-100">
      <div class="desktop-header_logo d-flex justify-content-between">
        <app-logo></app-logo>

        <a *ngIf="user.trial_user === 1 && selectedCourseService.shortCourse"
           class="ms-5 h6 back-to-course-button m-0 d-flex align-items-center" href="{{wpSiteUrl + '/' + selectedCourseService.shortCourse?.public_page_url}}/">
          <span class="mh-icon mh-icon-arrow-left me-1 mh-icon--lg"></span> Back to {{ selectedCourseService.shortCourse?.public_page_name }}
        </a>
      </div>

      <div class="desktop-header_courses d-flex justify-content-between" *ngIf="user.trial_user === 0">
        <div class="d-flex align-items-center fw-bold ps-5 pe-5"><a routerLinkActive="active"
                                                                    [routerLink]="['/courses/test_prep']"
                                                                    class="text-primary">Test Prep</a></div>

        <div class="d-flex align-items-center fw-bold"><a routerLinkActive="active" [routerLink]="['/courses/k12']"
                                                          class="text-primary">K12</a></div>

        <div class="d-flex align-items-center fw-bold ps-5 pe-5"><a routerLinkActive="active"
                                                                    [routerLink]="['/courses/college']"
                                                                    class="text-primary">College</a></div>
      </div>

      <div class="desktop-header_user">
        <app-user-badge></app-user-badge>
      </div>
    </div>
  </div>
</div>
