import {Injectable} from '@angular/core';
import {CourseShort} from "../../data-models/course-short";

@Injectable({
  providedIn: 'root'
})
export class SelectedCourseService {

  private _selectedCourseId: number;
  private _selectedCourseGroup: string;
  private _shortCourse: CourseShort;

  constructor() {
  }

  clear() {
    this._selectedCourseGroup = undefined;
    this._selectedCourseId = undefined;
  }

  selectCourseIdAndGroup(id: number, courseGroup: string): void {
    this._selectedCourseId = id;
    this._selectedCourseGroup = courseGroup;
  }

  selectShortCourse(shortCourse: CourseShort) {
    this._shortCourse = shortCourse;
  }

  get selectedCourseId(): number {
    return this._selectedCourseId;
  }

  get shortCourse() {
    return this._shortCourse;
  }

  get selectedCourseGroup(): string {
    return this._selectedCourseGroup;
  }
}
