<div class="mobile-header mobile-header--white-theme" *ngIf="(authService.getLoggedUserOnce() | async) as user">
  <div class="mobile-header_main d-flex justify-content-between align-items-center ps-3 pe-3"
       [class.extend-height]="user.trial_user === 1 && selectedCourseService.shortCourse">
    <div class="desktop-header_logo">
      <app-logo></app-logo>
    </div>

    <div (click)="showMenu = !showMenu" class="">
      <span *ngIf="!showMenu" class="mh-icon mh-icon-bars circled-icon"></span>

      <span *ngIf="showMenu" class="mh-icon mh-icon-cross circled-icon"></span>
    </div>
  </div>

  <a *ngIf="user.trial_user === 1 && selectedCourseService.shortCourse"
     class="ps-3 ms-1 mb-2 h6 back-to-course-button d-flex align-items-center" href="{{wpSiteUrl + '/' + selectedCourseService.shortCourse?.public_page_url}}/">
    <span class="mh-icon mh-icon-arrow-left me-1 mh-icon--lg"></span> Back to {{ selectedCourseService.shortCourse?.public_page_name }}
  </a>

  <div *ngIf="showMenu" class="mobile-header_menu">

    <div class="mobile-header_menu-courses p-3 d-flex justify-content-center align-items-start flex-column">

      <app-user-badge *appRoleAccess="'trialUser'"></app-user-badge>

      <div *appRoleAccess="'notTrialUser'" class="d-flex align-items-center fw-semi-bold"><a routerLinkActive="active"
                                                                                             (click)="showMenu = false"
                                                                                             [routerLink]="['/dashboard']"
                                                                                             [routerLinkActiveOptions]="routerActiveOptions"
                                                                                             [queryParams]="{showCoursesList: true}"
                                                                                             class="text-primary">{{ username }}</a>
      </div>

      <ng-container *ngIf="user.trial_user === 0">
        <div class="divider mt-2 mb-2"></div>

        <div class="d-flex align-items-center fw-semi-bold"><a routerLinkActive="active" (click)="showMenu = false"
                                                               [routerLink]="['/courses/test_prep']"
                                                               [routerLinkActiveOptions]="routerActiveOptions"
                                                               [queryParams]="{showCoursesList: true}" class="text-primary">Test
          Prep</a></div>

        <div class="d-flex align-items-center fw-semi-bold"><a routerLinkActive="active" (click)="showMenu = false"
                                                               [routerLink]="['/courses/k12']"
                                                               [routerLinkActiveOptions]="routerActiveOptions"
                                                               [queryParams]="{showCoursesList: true}" class="text-primary">K12</a>
        </div>

        <div class="d-flex align-items-center fw-semi-bold"><a routerLinkActive="active" (click)="showMenu = false"
                                                               [routerLink]="['/courses/college']"
                                                               [routerLinkActiveOptions]="routerActiveOptions"
                                                               [queryParams]="{showCoursesList: true}" class="text-primary">College</a>
        </div>
      </ng-container>
    </div>
  </div>
</div>
