<div class="mt-2 d-flex flex-column align-items-center"
     [class.text-success]="courseProgress"
     [class.text-gray]="!courseProgress"
     role="button"
     [routerLink]="courseProgress ? courseProgressRoute : null"
     [queryParams]="courseProgress ? courseProgressQuery : null">
  <div
    class="circle"
    [class.circle--green]="courseProgress"
    [class.circle--gray]="!courseProgress">
    <div
      [class.mh-icon--light-gray]="courseProgress"
      [class.mh-icon--dark-gray]="!courseProgress"
      class="mh-icon mh-icon-resume  mh-icon--xl"></div>
  </div>
  <div class="fw-semi-bold">
    <small>Resume</small>
  </div>
</div>
